<template>
    <div>
        <title-bar :title="$t('navigations.employees')"/>
        <div v-if="rows" class="custom-table">
            <table-actions
                :actions="['filterSelect', 'search', 'addBtn', 'perPage']"
                :addBtnText="$t('messages.employeeCreate')"
                :addBtnLink="$helper.getAddUrl('employees')"
                :option="filterOption"
                :options="filterOptions"
                @onSearchInput="inputChange"
                @perPageChange="perPageChange"
                @filterSelectChange="filterSelectChange"
                :defaultPerPage="perPage"
            />
            <b-alert variant="info" show>
                <i class="fas fa-info-circle"></i>
                You can now use the main search function to look up for 3LC.
            </b-alert>
            <table-lite
                :is-slot-mode="true"
                :is-loading="isLoading"
                :columns="columns"
                :rows="rows"
                :sortable="sortable"
                @do-search="setSort"
                @is-finished="isLoading = false"
            >
                <template v-slot:familyName="data">
                    {{
                        data.value.givenName + " " + data.value.familyName
                    }}
                </template>
                <template v-slot:userLetterCode="data">
                    {{ data.value.userLetterCode }}
                </template>
                <template v-slot:number="data">
                    {{ data.value.userNumber }}
                </template>
                <template v-slot:branch="data">
                    {{ data.value.branch ? data.value.branch.name : '' }}
                </template>
                <template v-slot:department="data">
                    {{ data.value.department ? data.value.department.name : '' }}
                </template>
                <template v-slot:function="data">
                    {{ data.value.companyPosition }}
                </template>
                <template v-slot:email="data">
                    {{ data.value.email }}<br>
                    {{ data.value.privateEmail }}
                </template>
                <template v-slot:phone="data">
                    {{ data.value.companyTelephone }}<br>
                    {{ data.value.privateTelephone }}
                </template>
                <template v-slot:enabled="data">
                    <b-badge :variant="data.value.enabled ? 'success' : 'danger'">{{
                            data.value.enabled ? 'ACTIVE' : 'INACTIVE'
                        }}
                    </b-badge>
                </template>
                <template v-slot:actions="data">
                    <div class="just-buttons">
                        <edit-btn
                            :path="$helper.getEditUrl('employees', data.value.id)"
                        />
                        <delete-btn @pressDelete="deleteEmployee(data.value['@id'])"/>
                    </div>
                </template>
            </table-lite>
            <table-pagination
                v-model="currentPage"
                :total="pages"
                :per-page="perPage"
                @input="onChangePage"
            />
        </div>
    </div>
</template>

<script>

import TitleBar from "@/components/TitleBar.vue";
import TableLite from "vue3-table-lite";
import TablePagination from "@/components/Table/TablePagination.vue";
import TableActions from "@/components/Table/TableActions.vue";
import EditBtn from "@/components/Buttons/EditBtn.vue";
import DeleteBtn from "@/components/Buttons/DeleteBtn.vue";
import ResourceUtils from "@/mixins/ResourceUtils";

export default {
    name: "AdminHolidayListing",
    components: {
        TableLite,
        TablePagination,
        TableActions,
        EditBtn,
        DeleteBtn,
        TitleBar
    },
    mixins: [ResourceUtils],
    data() {
        return {
            search: "",
            isLoading: false,
            rows: null,
            perPage: this.$store.state.defaultPerPage,
            currentPage: 1,
            pages: 0,
            enabled: true,
            filterOption: {
                id: true,
                label: "Active"
            },
            filterOptions: [
                {
                    id: true,
                    label: "Active"
                },
                {
                    id: false,
                    label: "Inactive"
                },
                {
                    id: "all",
                    label: "All"
                }
            ],
            sortable: {
                order: "familyName",
                sort: "asc",
            },
            columns: [
                {
                    label: this.$t("forms.name"),
                    field: "familyName",
                    sortable: true,
                },
                {
                    label: this.$t("forms.3lc"),
                    field: "userLetterCode",
                    sortable: true,
                },
                {
                    label: this.$t("forms.number"),
                    field: "userNumber",
                    sortable: true,
                },
                {
                    label: this.$t("forms.branch"),
                    field: "branch",
                    sortable: true,
                },
                {
                    label: this.$t("forms.department"),
                    field: "department",
                    sortable: true,
                },
                {
                    label: this.$t("forms.function"),
                    field: "function",
                    sortable: false,
                },
                {
                    label: this.$t("forms.email"),
                    field: "email",
                    sortable: false,
                },
                {
                    label: this.$t("forms.telephone"),
                    field: "phone",
                    sortable: false,
                },
                {
                    label: this.$t("forms.status"),
                    field: "enabled",
                    sortable: true,
                },
                {
                    label: this.$t("forms.actions"),
                    field: "actions",
                    sortable: false,
                    width: "80px",
                },
            ],
        };
    },
    created() {
        this.load();
    },
    methods: {
        load() {
            const params = {
                itemsPerPage: this.perPage,
                page: this.currentPage,
                role: "ROLE_EMPLOYEE",
                enabled: this.enabled
            };

            params["order[" + this.sortable.order + "]"] = this.sortable.sort;
            if (this.search && this.search.length) {
                params.search = this.search;
            }
            this.$Users.getCollection({params}).then((response) => {
                this.pages = response.data["hydra:totalItems"];
                this.rows = response.data["hydra:member"];
            });
        },
        setSort(offset, limit, order, sort) {
            this.sortable.order = order;
            this.sortable.sort = sort;
            if (order === 'department' || order === 'branch') {
                this.sortable.order = order + '.name';
            }

            this.load();
        },
        onChangePage(page) {
            this.currentPage = page;
            this.load();
        },
        deleteEmployee(url) {
            this.deleteByUrl(
                this.$Users,
                url,
                this.$t("messages.employeeDeleted"),
                null,
                this.load,
                this.error
            );
        },
        inputChange(value) {
            this.currentPage = 1;
            this.search = value;
            this.load();
        },
        onPageChange() {
            this.load();
        },
        perPageChange(num) {
            this.currentPage = 1;
            this.perPage = num;
            this.load();
        },
        filterSelectChange(enabled) {
            this.enabled = enabled.id
            if (enabled.id === "all") {
                this.enabled = [true, false]
            }
            this.load()
        },
        error() {
            this.$emit("clearAction");
        },
    },
};
</script>

<style lang="scss">
</style>
